import React from "react"
import parse from "html-react-parser"

import Section from "./Section"
import "aos/dist/aos.css"

const PageContentSectionMd = ({ title, copy }) => {
  return (
    <>
      <Section additionalClasses="section has-background-bis has-background-black-bis has-text-white page-title section-z-index">
        <div className="content-block">
          <div
            className="content-padding content-page has-text-white is-6 content-md"
            data-aos="fade-up"
            data-aos-offset="10"
            data-aos-delay="10"
            data-aos-duration="1000"
          >
            <h2 className="is-size-3 is-size-3-mobile has-text-weight-bold">
              {title ? title : "How we do it."}
            </h2>
            {copy && (
              <div className="content-padding is-size-para is-size-para-mobile ">
                {parse(copy)}
              </div>
            )}
          </div>
        </div>
      </Section>
    </>
  )
}

export default PageContentSectionMd
