import React, { useEffect, useState } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { getImage } from "gatsby-plugin-image"

gsap.registerPlugin(ScrollTrigger)

const LogoList = ({ title, subtitle, images }) => {
  useEffect(() => {
    const logosLeft = document.querySelectorAll(
      ".logo-list__group--left .logo-list__image"
    )
    const logosRight = document.querySelectorAll(
      ".logo-list__group--right .logo-list__image"
    )

    gsap.to(logosLeft, {
      marginRight: index => `${index + 60}px`,
      opacity: 1, // Initial opacity
      ease: "none",
      scrollTrigger: {
        trigger: ".logo-list",
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        onUpdate: self => {
          logosLeft.forEach((logo, index) => {
            logo.style.opacity = Math.max(0, 1 - self.progress)
          })
        },
      },
    })

    gsap.to(logosRight, {
      marginLeft: index => `${index + 60}px`,
      opacity: 1, // Initial opacity
      ease: "none",
      scrollTrigger: {
        trigger: ".logo-list",
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        onUpdate: self => {
          logosRight.forEach((logo, index) => {
            logo.style.opacity = Math.max(0, 1 - self.progress)
          })
        },
      },
    })
  }, [])

  return (
    <>
      <div className="section bg-black logo-list-section">
        <div className="content-block has-text-centered has-text-white">
          <h2 className="is-size-3 is-size-3-mobile has-text-weight-bold">
            {title}
          </h2>
          <p className="has-text-weight-semibold">{subtitle}</p>
        </div>
        <div className="logo-list desktop">
          <div className="logo-list__group logo-list__group--left">
            {images.map((image, index) => {
              if (index < images.length / 2) {
                return (
                  <img
                    src={getImage(image.localFile).images.fallback.src}
                    key={index}
                    className="logo-list__image"
                  />
                )
              }
            })}
          </div>
          <div className="logo-list__group logo-list__group--right">
            {images.map((image, index) => {
              if (index > images.length / 2) {
                return (
                  <img
                    src={getImage(image.localFile).images.fallback.src}
                    key={index}
                    className="logo-list__image"
                  />
                )
              }
            })}
          </div>
        </div>
        <div className="logo-list mobile">
          <div className="logo-list__group logo-list__group--left">
            <div className="d-flex">
              {images.map((image, index) => {
                if (index < 5) {
                  return (
                    <img
                      src={getImage(image.localFile).images.fallback.src}
                      className="logo-list__image"
                    />
                  )
                }
              })}
            </div>
            <div className="d-flex">
              {images.map((image, index) => {
                if (index > 4 && index < 10) {
                  return (
                    <img
                      src={getImage(image.localFile).images.fallback.src}
                      className="logo-list__image"
                    />
                  )
                }
              })}
            </div>
          </div>
          <div className="logo-list__group logo-list__group--right">
            <div className="d-flex">
              {images.map((image, index) => {
                if (index > 9 && index < 15) {
                  return (
                    <img
                      src={getImage(image.localFile).images.fallback.src}
                      className="logo-list__image"
                    />
                  )
                }
              })}
            </div>
            <div className="d-flex">
              {images.map((image, index) => {
                if ((index > 14 && index < images.length + 1) || index === 10) {
                  return (
                    <img
                      src={getImage(image.localFile).images.fallback.src}
                      className="logo-list__image"
                    />
                  )
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LogoList
