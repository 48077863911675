import React, { useState, useEffect } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import parse from "html-react-parser"

import { motion } from "framer-motion"
import bgMountain1 from "../assets/mountains-dot/mountains-dot-1.svg"
import bgMountain2 from "../assets/mountains-dot/mountains-dot-2.svg"
import bgMountain3 from "../assets/mountains-dot/mountains-dot-3.svg"
import bgMountain4 from "../assets/mountains-dot/mountains-dot-4.svg"

import "aos/dist/aos.css"

const MountainHeaderLarge = ({ tabs }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [fade, setFade] = useState(false)
  const tabImage = [bgMountain1, bgMountain2, bgMountain3, bgMountain4]

  const textMotion = {
    rest: {
      y: 0,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeIn",
      },
    },
    hover: {
      y: -25,
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut",
      },
    },
  }
  useEffect(() => {
    const AOS = require("aos")
    AOS.init({
      duration: 400,
      offset: 75,
      delay: 75,
      once: true,
      mirror: true,
      easing: "ease",
    })

    if (AOS) {
      AOS.refresh()
    }
  }, [])

  const handleTabChange = index => {
    setFade(true)
    setTimeout(() => {
      setFade(false)
      setActiveIndex(index)
    }, 100) // match the duration of the fade-out animation
  }
  return (
    <>
      <div
        className={`mountainSectionBottom has-background-bis has-text-white`}
      >
        <div className="mountain-image-lg foreground">
          {tabImage.map((image, index) => (
            <div
              key={index}
              className={`image-block ${
                index === activeIndex ? "active" : "hidden"
              }`}
            >
              <img src={image} alt="" />
            </div>
          ))}
        </div>
        <div
          className="container mountain-tabs"
          data-aos="fade-up"
          data-aos-offset="-100"
          data-aos-delay="10"
          data-aos-duration="1000"
        >
          <motion.div variants={textMotion}>
            <div className="tabs-content has-text-white">
              <button
                aria-label="Previous Tab"
                className="button-arrow button-arrow-prev"
                onClick={() =>
                  handleTabChange((activeIndex - 1 + tabs.length) % tabs.length)
                }
                disabled={activeIndex === 0}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.589"
                  height="21.047"
                  viewBox="13.411 2091.305 10.589 21.047"
                >
                  <path
                    d="M22.497 2112.349a1.5 1.5 0 0 0 1.155-2.46l-6.72-8.04 6.48-8.055a1.5 1.5 0 0 0-.225-2.115 1.5 1.5 0 0 0-2.19.225l-7.245 9a1.5 1.5 0 0 0 0 1.905l7.5 9a1.5 1.5 0 0 0 1.245.54Z"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                </svg>
              </button>
              <button
                aria-label={`Next Tab`}
                onClick={() => handleTabChange((activeIndex + 1) % tabs.length)}
                className="button-arrow button-arrow-next"
                disabled={activeIndex === tabs.length - 1}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.589"
                  height="21.047"
                  viewBox="365.95 2091.305 10.589 21.047"
                >
                  <path
                    d="M367.452 2112.349a1.5 1.5 0 0 1-1.155-2.46l6.72-8.04-6.48-8.055a1.5 1.5 0 0 1 .225-2.115 1.5 1.5 0 0 1 2.19.225l7.245 9a1.5 1.5 0 0 1 0 1.905l-7.5 9a1.5 1.5 0 0 1-1.245.54Z"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                </svg>
              </button>
              <Tabs
                selectedIndex={activeIndex}
                onSelect={index => handleTabChange(index)}
                className={"tabs-container"}
                selectedTabClassName="selected"
              >
                <TabList className={"tabs-list"}>
                  {tabs.map((tab, index) => (
                    <Tab key={index} className={"tab-item"}>
                      {tab.tabTitle}
                    </Tab>
                  ))}
                </TabList>

                <div
                  className={`tab-block`}
                  data-aos-once="true"
                  data-aos="fade-in"
                >
                  {tabs.map((tab, index) => (
                    <TabPanel
                      key={index}
                      className={`tab-content ${fade ? "fade-out" : "fade-in"}`}
                    >
                      <h2 className="is-size-4 is-size-4-mobile has-text-weight-semibold">
                        {tab.tabTitle}
                      </h2>
                      <p>{parse(tab.copy)}</p>
                    </TabPanel>
                  ))}
                </div>
              </Tabs>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  )
}
export default MountainHeaderLarge
