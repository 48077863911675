import React from "react"
import parse from "html-react-parser"

import Section from "./Section"
import "aos/dist/aos.css"

const PageCopyContent = ({ leftContent, rightContent }) => {
  return (
    <>
      <Section
        additionalClasses={`section double-top-padding page-content-with-logo has-background-black-bis copy-section`}
      >
        <div
          className="page-content"
          data-aos={"fade-up"}
          data-aos-offset="40"
          data-aos-delay="10"
          data-aos-duration="1000"
        >
          <div className="copy-two-blocks">
            <div className="is-size-para content-page is-size-para-mobile has-text-white is-9 content-left">
              {parse(leftContent)}
            </div>
            <div className="is-size-para content-page is-size-para-mobile has-text-white is-9 content-right">
              {parse(rightContent)}
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default PageCopyContent
