import React, { useEffect } from "react"
import { graphql } from "gatsby"

/* NPM */
import "aos/dist/aos.css"

/* Indie Components */
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import MountainHeader from "../components/MountainHeader"
import PageWrapper from "../components/PageWrapper"
import PageTitleSection from "../components/PageTitleSection"
import MountainHeaderLarge from "../components/MountainHeaderLarge"
import LogoList from "../components/LogoList"
import PageCopyContent from "../components/PageCopyContent"
import PageContentSectionMd from "../components/PageContentSectionMd"

const ServicesWebsites = ({ data: { page } }) => {
  useEffect(() => {
    const offset = window.innerWidth > 500 ? 360 : 180
    const AOS = require("aos")
    AOS.init({
      duration: 700,
      offset: offset,
      disable: function () {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(
          navigator.userAgent
        )
      },
    })

    if (AOS) {
      AOS.refresh()
    }
  }, [])

  return (
    <Layout
      showFooter={page.pageSettings.showFooter}
      footerTitle={"Want to explore more?"}
    >
      <Seo seo={page.seo} />
      <MountainHeader headerImages={page.headerSettings.headerImages} />
      <PageWrapper additionalClassName="services-page">
        <PageTitleSection
          title={page.headerSettings.title}
          subtitle={page.headerSettings.subTitle}
          description="<p> Welcome to the future of web design, where speed meets style and SEO
          optimisation. Our websites aren’t just fast; they’re hyper-fast, crafted
          to catapult your digital presence to the top of Google’s rankings and
          keep your audience engaged.</p><p>Imagine a website that’s not only fast and unique but also favoured by
          Google. With an intuitive interface, users can easily publish everything
          from thought leadership articles to the latest product descriptions,
          eliminating the dependency on tech support for every website update.</p><p> Our approach combines innovative technology, creative design, and
          world-class SEO knowledge that shatters the mould of traditional
          websites.</p>"
        />
        <PageContentSectionMd
          title={page.ServiceWebsiteFields.pageContentMd.title}
          copy={page.ServiceWebsiteFields.pageContentMd.copy}
        />
        <MountainHeaderLarge
          tabs={page.ServiceWebsiteFields.howWeDoItWebsite}
        />
        <LogoList {...page.ServiceWebsiteFields.whatWeUse} />
        <PageCopyContent {...page.ServiceWebsiteFields.bottomContentWebsite} />
      </PageWrapper>
    </Layout>
  )
}

export default ServicesWebsites

export const ServicesWebsitesQuery = graphql`
  query ServicesWebsitesId(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      ...pageFields
      ServiceWebsiteFields {
        howWeDoItWebsite {
          title
          tabTitle
          copy
        }
        whatWeUse {
          title
          subtitle
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        bottomContentWebsite {
          leftContent
          rightContent
        }
        pageContentMd {
          title
          copy
        }
      }
    }
  }
`
